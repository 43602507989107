import React from "react";
import { useContextType } from "../@types/ContextTypes";

const UserContext = React.createContext<useContextType>({
  // screenState: 1,
  // isInternetConnected: true,
  // userBankAccount: {
  //   bankName: null,
  //   accountNumber: null,
  // },
  // userData: {
  //   id: null,
  //   msisdn: null,
  //   subDate: null,
  //   cnic: null,
  //   dob: null,
  //   email: null,
  //   gender: null,
  //   name: null,
  //   profilePicUrl: null,
  //   residentialAddress: null,
  //   accountNumber: null,
  //   bankName: null,
  //   bankId: null,
  // },
  insuranceCategories: [],
  // userPolicies: null,
  // updateUserBankAccount: () => {},
  // updateScreenState: () => {},
  // updateIsInternetConnected: () => {},
  // updateUserData: () => {},
  updateInsuranceCategories: () => {},
  // updateUserPolicies: () => {},
});

export default UserContext;
