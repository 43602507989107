import React from 'react'
import "../styles/policy.css";

const TaqdeerPrivacyPolicy = () => {
  return (
    <div class="jazz_discount_outer">
      <div class="container">
        <div class="discount_inner">
          <h2>FIKRFREE PRIVACY NOTICE</h2>

          {/* <h3> Scope and Application</h3> */}
          <p>
            FikrFree is an Insurance Market Aggregator App that offers a wide range of insurance products
            conveniently in one place. Whether it's health insurance or car insurance, FikrFree ensures
            comprehensive coverage across all aspects. Our goal is to provide peace of mind by safeguarding
            every facet of life, allowing customers to live worry-free!<br></br><br></br>
          </p>

          <h3> Scope and Application</h3>
          <br></br>
          <p>
            We prioritize your privacy and handle your personal information with the utmost care, in compliance
            with applicable laws of Pakistan. Our privacy policy outlines how we transparently collect, store,
            use, and disclose your personal information. Please read this notice along with the Jazz Customer
            Privacy Notice at <a href='https://www.jazz.com.pk/help/customer-privacy-policy/'>https://www.jazz.com.pk/help/customer-privacy-policy/</a> to fully understand the
            measures taken to safeguard your privacy. <br></br><br></br>
          </p>


          <h3> Information We Collect About You</h3>
          <br></br>
          <p>
            Through our mobile application (FikrFree App) and website (FikrFree Web), we collect personal
            information, including but not limited to your name, mobile number, email address, physical address,
            CNIC, technical data (such as location, usage, preferences, Bluetooth permission, notification
            permissions, picture uploads, device information, browser information, active time, service usage,
            interface usage habits, error information, subscription data, device model, operating system and
            operator information). <br></br><br></br>
          </p>


          <h3> How We Collect your information</h3>
          <br></br>
          <p> We collect and process data when you:<br></br><br></br> </p>
          <ul>
            <li>
              Register online, through our mobile application (FikrFree App) for any of our services.
            </li>
            <li>
              Voluntarily complete a customer survey or provide feedback on any of our message board or
              via email.
            </li>
            <li>Use or view our website via your browser’s cookies.</li>
          </ul>
          <br></br>
          <h3> How We Use Your Information</h3>
          <br></br>
          <p> We use this data to facilitate subscriptions and deliver a personalized app experience, which involves
            analyzing preferences and usage patterns. This includes customer profiling, providing customer
            support, selecting and announcing winners in incentivized campaigns, managing subscriptions, and
            processing un-subscriptions. Additionally, location data may be collected for geo-fencing purposes,
            as this service is exclusive to Pakistan.<br></br>

            We collect your data so that we can process the same, and email/ notify you with any special offers
            on services we think you might like. <br></br><br></br></p>


          <h3>Usage and Safety of Your Information</h3>
          <br></br>
          <p> Security is our top priority. We employ various technical and organizational measures such as
            encryption, access controls, firewalls, and regular security assessments to protect your data from
            unauthorized access, disclosure, alteration, or destruction.<br></br><br></br></p>

          <h3>Marketing </h3>
          <br></br>
          <p> We would like to send you information about products and services of ours that we think you might
            like, as well as those of our partner companies.<br></br>
            For details on opting out of marketing materials and analytics, kindly refer to the Jazz Privacy Notice
            available at the following link: <a href='https://jazz.com.pk/help/customer-privacy-policy/'>https://jazz.com.pk/help/customer-privacy-policy/</a><br></br><br></br></p>

          <h3> Sharing Your Information</h3>
          <br></br>
          <p> We rely on a trusted third-party service to handle essential functions, ensuring the secure
            management of user data, library and metadata. Simultaneously, our authentication process protects
            your information and enables effective user management. You can enjoy real-time updates for instant
            content additions and seamless user interactions, with media files stored and delivered securely. Our
            use of analytics helps us gain insights into user behavior, and continuous performance monitoring
            guarantees a smooth app experience.</p>
          <p>Rest assured that your data security and app performance are our utmost priorities. In certain
            instances, we may also need to share Personal Data to provide specific services, taking into account
            your product or service usage, and strictly adhering to legal, regulatory, and security requirements
            with rigorous authorization and privacy measures. Your trust in us matters, and we're dedicated to
            safeguarding your privacy and ensuring a top-notch app experience.<br></br><br></br></p>


          <h3>  Data Retention</h3>
          <br></br>
          <p> We retain personal data for three months, ensuring compliance with applicable data retention laws.
            We prioritize your privacy while adhering to regulatory obligations.<br></br><br></br></p>

          <h3> Cookies and Tracking Technologies</h3>
          <br></br>
          <p>We use cookies on our website and advertiser IDs in the FikrFree mobile application for tracking.
            Users agree to the terms and conditions and consent to the use of their data, provided it is not shared
            outside of affiliated group companies.<br></br><br></br></p>

          <h3>  Handling Data Breaches</h3>
          <br></br>
          <p> We have automated application alerts and comprehensive data security policies in place for a prompt
            response to any breaches.<br></br><br></br></p>

          <h3> Consent and Opt-Out of Data Processing</h3>
          <br></br>
          <p> You can choose to opt out of our platform in several ways: by unsubscribing through the FikrFree
            App or or by contacting the given helpline in the App. You can also choose to unsubscribe the service
            and your data will be purged as per our data retention policy. Moreover, you have the convenience
            of managing notifications directly in your device settings. Should you seek further insight or
            clarification about our privacy practices and data retention policy, please feel free to reach out to us.
            Rest assured, your privacy holds the highest priority in our commitment to providing you with a
            trusted experience through FikrFree and we deeply appreciate the trust you've placed in us.<br></br><br></br></p>

          <h3> Changes to the Privacy Notice</h3>
          <br></br>
          <p> We understand the importance of keeping you informed about changes to our privacy notice. The
            updated notice is published via the Application and significant changes are notified through in-app
            notifications. Please review the Policy section regularly, as it may vary depending on usage and
            requests. We remain committed to ensuring your privacy and security in all updates.<br></br><br></br></p>

          <h3> How to contact us </h3>
          <br></br>
          <p> If you have any questions about our privacy policy, please do not hesitate to contact us.<br></br><br></br></p>


          <br></br>
          
          <p><b>Email us at:</b> <a href="mailto:support@econceptions.mobi">support@econceptions.mobi</a>
            <br></br>
            <b>Address: </b>[1st Floor, Plot 12-C/2, Mandir Square, G-8 Markaz, Islamabad]</p>
          <p>For inquiries about the handling of your personal data—including collection, usage, disclosure,
            transfer, processing—or to exercise your rights, or if you have a complaint or feel your concerns
            haven't been adequately resolved, please reach out to the Data Protection Officer at
            dpo@jazz.com.pk.
          </p>
        </div>
      </div>
    </div>
  )
}

export default TaqdeerPrivacyPolicy