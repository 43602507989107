import React from "react";
import "../styles/Profile.css";
import camera from "../Assets/imgs/camera.webp";
import backarrow from "../Assets/imgs/back-arrow.webp";
import profileicon from "../Assets/imgs/profile-icon.webp";
import profileshadow from "../Assets/imgs/profile-shadow.webp";
import { formatCnic } from "../utils/CommonFunctions";
import { imageUrl } from "../utils/Config";
import SmallLoader from "./common/SmallLoader";
import moment from "moment";

interface ProfileProps {
  inputValue: any;
  gender: string;
  dateOfBirth: any;
  selectedImage: any;
  onAddressChange: (val: string) => void;
  onEmailChange: (val: string) => void;
  onNameChange: (val: string) => void;
  onLastNameChange: (val: string) => void;
  onCnicChange: (val: string) => void;
  onGenderChange: (val: string) => void;
  onDateOfBirthChange: (val: any) => void;
  onSubmitData: () => void;
  imgInputRef: any;
  onOpenImageDialog: () => void;
  onImageSelect: (e: any) => void;
  errorMsg: string;
  inputError: any;
  errorText: any;
  isLoading: boolean;
  isImageUpdating: boolean;
}

const Profile = (props: ProfileProps) => {
  return (
    <>
      <div className="profile-page">
        <div className="container">
          <div className="col-md-12">
            <div className="back-profile">
              {/* <img src={backarrow} alt="" />  */}
              <span>Profile</span>
            </div>

            <div className="profile-icon">
              {props.isImageUpdating ? (
                <div className="user-profile-loading">
                  <SmallLoader />
                </div>
              ) : (
                <img
                  src={
                    props.selectedImage
                      ? imageUrl + props.selectedImage
                      : profileicon
                  }
                  className="user-profile-icon"
                  alt=""
                />
              )}
              <img
                src={camera}
                onClick={props.onOpenImageDialog}
                className="camera"
                style={{ cursor: "pointer" }}
                alt=""
              />
              <input
                type="file"
                ref={props.imgInputRef}
                style={{ display: "none" }}
                id="fileInput"
                accept="webp png jpg jpeg"
                onChange={(e) => {
                  props.onImageSelect(e);
                }}
              />
              <img src={profileshadow} className="profileshadow" alt="" />
            </div>
          </div>
          {/* form */}
        </div>
      </div>

      {/* inputs */}
      {/* <div className='inputs'> */}
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-5 col-12">
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                // placeholder="Enter Name Here"
                onChange={(e) => props.onNameChange(e.target.value)}
                value={props.inputValue?.firstName}
                maxLength={20}
              />
              {props.inputError.firstName && (
                <p className="error-msg">{props.errorText.firstName}</p>
              )}
            </div>
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={20}
                onChange={(e) => props.onLastNameChange(e.target.value)}
                // placeholder="Enter Name Here"
                value={props.inputValue?.lastName}
              />
              {props.inputError.lastName && (
                <p className="error-msg">{props.errorText.lastName}</p>
              )}
            </div>
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Gender
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={props.gender}
                onChange={(e) => props.onGenderChange(e.target.value)}
              >
                <option value={props.gender} selected>
                  {props.gender}
                </option>
                <option value={props.gender === "Male" ? "Female" : "Male"}>
                  {props.gender === "Male" ? "Female" : "Male"}
                </option>
                {/* <option value="1">One</option>
                <option value="3">Three</option> */}
              </select>
            </div>
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                CNIC
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={15}
                onChange={(e) => {
                  let formatted = formatCnic(e.target.value);
                  props.onCnicChange(formatted);
                }}
                // placeholder="xxxxx-xxxxxxxx-x"
                value={props.inputValue?.cnic}
              />
              {props.inputError.cnic && (
                <p className="error-msg">{props.errorText.cnic}</p>
              )}
            </div>
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                maxLength={255}
                onChange={(e) => props.onEmailChange(e.target.value)}
                // placeholder="i.hash34@gmail.com"
                value={props.inputValue?.email}
              />
              {props.inputError.email && (
                <p className="error-msg">{props.errorText.email}</p>
              )}
            </div>
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Date of Birth
              </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => props.onDateOfBirthChange(e.target.value)}
                // placeholder="Enter Name Here"
                value={props.dateOfBirth}
                max={moment().subtract(18, "years").format("YYYY-MM-DD")}
              />
              {props.inputError.dob && (
                <p className="error-msg">{props.errorText.dob}</p>
              )}
            </div>
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Residential Address
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={300}
                onChange={(e) => props.onAddressChange(e.target.value)}
                // placeholder="Enter Name Here"
                value={props.inputValue?.residentialAddress}
              />
              {props.inputError.residentialAddress && (
                <p className="error-msg">
                  {props.errorText.residentialAddress}
                </p>
              )}
            </div>
            {props.errorMsg && (
              <div className="error-msg">{props.errorMsg}</div>
            )}

            {props.isLoading ? (
              <div className="d-btn">
                <button>Updating Profile</button>
              </div>
            ) : (
              <div className="d-btn">
                <button onClick={props.onSubmitData}>Save</button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Profile;
