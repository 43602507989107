import React from 'react'
import "../styles/policy.css";
const TaqdeerTermsAndConditions = () => {
    return (
      <div class="jazz_discount_outer">
        <div class="container">
          <div class="discount_inner">
            <h2>Terms & Conditions and Content policy.</h2>
            <h3>Terms of Use</h3>
            <p>
              Salam/Greetings! and very warm welcome to the Terms of Use of
              Fikrfree (“Terms ”). These Terms are between you and Pakistan
              Mobile Communications Limited, a company registered under the laws
              of Pakistan (“PMCL” “We”, “Us”, “Our”) providing the [Fikrfree]
              mobile application (the “Platform”) to you on your location.
              Please read these Terms, along with the privacy policy (the
              “Privacy Policy”) applicable to you, and all other rules and
              policies related to the Platform. Each time you visit, browse, or
              use the Platform, you accept these Terms and Privacy Notice and
              you agree to be bound by them Your agreement with us includes
              these Terms.
            </p>
            <p>
              The Platform utilizes various features of your device to enhance
              functionality. This includes location services, sensors such as
              the altimeter and accelerometer, and modifications to memory and
              storage. Additionally, it accesses phone statistics, identity
              details, and device features like file systems, contacts, call
              logs, camera, SMS inbox, and Wi-Fi connections.
            </p>
            <p>These features serve specific purposes:</p>
            <p>
              - Location Services: Enable us to offer insurance policies
              tailored to your location and send location based notifications.
              <br></br>- Camera/Gallery Access: Facilitate document uploads for
              efficient verification and settlement of insurance claims.
              <br></br>- Logs and History: Maintain a transparent record of your
              interactions and transactions for your reference.<br></br>
            </p>
            <p>
              {" "}
              By continuing to use the Platform, you consent to these uses of
              your device's features, enhancing your overall insurance
              experience.
            </p>
            <p>
              <b>
                {" "}
                IF YOU DO NOT AGREE WITH THESE TERMS, THEN PLEASE REFRAIN FROM
                USING THE PLATFORM. BY ACCESSING OR USING THE PLATFORM, YOU
                IRREVOCABLY ACCEPT THE AGREEMENT AND AGREE TO ABIDE BY THE SAME
                (AS UPDATED FROM TIME TO TIME).
              </b>
            </p>
            <p>
              If you have downloaded the Platform from the Google Play Store
              (for Android users) or the App Store (for IOS users), You will
              also be subject to Google Play and/or App Store Terms of Service,
              respectively. If there is any conflict between Google Play Terms
              of Service and/or App Store Terms of Service and this Agreement
              concerning your use of the App, then, this Agreement shall
              prevail.
            </p>
            <h3>2. SERVICES</h3>
            <br></br>
            <p>
              {" "}
              2.1 The Platform provides a range of services (collectively the
              “Services”) which include the FikrFree mobile application / App,
              website, on through which the Users will have access to insurance
              services which the Users may Purchase as they deem fit.
            </p>
            <br></br>
            <p>
              {" "}
              2.2 The names, images and logos identifying PMCL and its
              businesses, affiliates or third parties and their products and
              services are proprietary marks of PMCL and/or the respective
              affiliates or third parties. Nothing contained in these Terms
              shall be construed as conferring any licence or right under any
              trademark of PMCL or the respective affiliates or any other third
              party.
            </p>
            <br></br>
            <h3> 3. THIRD PARTY SERVICE PROVIDERS</h3>
            <br></br>
            <p>
              {" "}
              3.1 The Platform provides access to certain third-party service
              providers. Third-party providers of websites and applications that
              you access through the Platform are accessed at your own risk, and
              we have no liability for any issues that may arise from using such
              third-party services. If any third-party websites or applications
              cause any problems or harm, you should seek redress directly from
              the third-party provider. We have no responsibility in such
              matters and therefore, cannot assist. It is also important to note
              that third-party providers of websites or applications that you
              access through the Platform may have their terms and conditions.
              It is your responsibility to read and adhere to such terms and
              conditions.
            </p>
            <br></br>
            <p>
              3.2 PMCL warrants that it will perform its obligations under these
              Terms in a professional manner, by industry standards, and will
              make reasonable efforts to keep the Platform operational. However,
              the Platform is provided on an “as is” basis and PMCL makes no
              warranties, expressed or implied, concerning the availability,
              merchantability, fitness for a particular purpose, non
              infringement, accuracy or completeness, of the Platform or the
              third-party websites or applications available on the Platform.
              You acknowledge that the Platform may contain errors and that PMCL
              does not guarantee its uninterrupted or error-free operation.
            </p>
            <br></br>
            <h3> 4. MODIFICATIONS, UPDATED AND AMENDMENTS</h3>
            <br></br>
            <p>
              {" "}
              4.1 PMCL reserves the right to change the Terms under which the
              Services are offered, including but not limited to the charges, if
              any, associated with the use of the Services. The Terms may be
              further modified based on changes in the business, legal and
              regulatory requirements and will be updated online. You are
              encouraged to periodically visit this page to review these Terms
              and any changes to it. PMCL reserves the right to add, modify or
              delete any content or features available in the Platform at any
              time at its sole discretion.{" "}
            </p>
            <br></br>
            <p>
              4.2 PMCL reserves the right to suspend or discontinue the Services
              or any part thereof, at any time and without notice (except as
              required pursuant to applicable law) and PMCL will not be liable
              to you should it exercise such rights, even if your ability to use
              the Service is impacted by the modification or change.{" "}
            </p>
            <br></br>
            <p>
              4.3 PMCL reserves the right to make changes to the Terms and the
              Privacy Notice for legal or regulatory reasons; for security
              reasons; to enhance existing features and/or add additional
              features to the Services; to reflect advancements in technology;
              to make reasonable technical adjustments to the Services; and to
              ensure the ongoing operability of the Service at any time by
              posting the revised terms in connection with the Services. To the
              maximum extent permitted by law, your continued use of the
              Services or the Platform following any changes will constitute
              your acceptance of such changes.{" "}
            </p>
            <br></br>
            <h3> 5. USING THE PLATFORM</h3>
            <br></br>
            <p>
              {" "}
              5.1 You are responsible for maintaining the confidentiality of
              your user identification, password, account details and related
              private information. This is to ensure that your account and its
              related details are maintained securely at all times and all
              necessary steps are taken to prevent misuse of your account. You
              should inform us immediately if you have any reason to believe
              that your password has become known to anyone else, or if the
              password is being, or is likely to be, used in an unauthorized
              manner. You agree and acknowledge that any use of the Platform and
              related services offered and/or any access to private information,
              data or communications using your account and password shall be
              deemed to be either performed by you or authorized by you as the
              case may be.{" "}
            </p>
            <br></br>
            <p>
              5.2 If you permit anyone else to use or access the Platform using
              your account, you acknowledge that these Terms as well as any
              consents you may have provided also applies to their access, use,
              and disclosure of information.
            </p>
            <br></br>
            <p>5.3 By accessing or using the Platform:</p>
            <br></br>
            <p>
              (a) You confirm and warrant that all the data and information you
              supply to PMCL and or any PMCL's affiliates ("Registration Data")
              is accurate in all respects and at all times.{" "}
            </p>
            <br></br>
            <p>
              (b) You agree to promptly update your Registration Data as
              necessary, so that your Registration Data remains accurate at all
              times.{" "}
            </p>
            <br></br>
            <p>
              (c) You acknowledge that PMCL contracts with you and is
              responsible for distributing the Platform in Pakistan and that
              PMCL is the data controller as set out in detail in the Privacy
              Notice{" "}
            </p>
            <br></br>
            <p>
              5.4 When you use the Platform, you may be given the opportunity to
              consent to or opt out from receiving communications from us
              through email, text, and/or mobile push notifications or any other
              method as PMCL may provide. Standard text and calling rates will
              apply. You agree that texts, calls, or pre-recorded messages may
              be generated by automatic telephone dialling systems. For details
              on opting out of marketing material, kindly refer to the Privacy
              Notice. You acknowledge that you are not required to consent to
              receive promotional texts, emails, or calls or any other
              communication as a condition of using the Platform.{" "}
            </p>
            <br></br>
            <p>
              5.5 During Service activation, the subscriber consents to
              receiving text messages for new features/updates in the Platform.
              Text messages are sent from PMCL’s Network which does not incur
              any charges.{" "}
            </p>
            <br></br>
            <h3> 6. General Disclaimers</h3>
            <br></br>
            <p>
              {" "}
              6.1 By using the Platform, You agree that You are fully able and
              competent to understand and accept this Agreement as a binding
              contract and to abide by all Terms
            </p>
            <br></br>
            <p>
              {" "}
              6.2 You shall not access the Platform by any other means other
              than through the interfaces that are provided and not acquire,
              copy, or monitor any portion of the Platform in any way to
              reproduce or circumvent the navigational structure or presentation
              of the Platform, to obtain or attempt to obtain any materials,
              documents, or information through any means not specifically made
              available through the Platform.
            </p>
            <br></br>
            <p>
              {" "}
              6.3 You shall not host, display, upload, modify, publish, or share
              any information on the Platform that belongs to another person to
              which You do not have any right; or is grossly harmful,
              blasphemous, defamatory, obscene, pornographic, pedophilic,
              libelous, invasive of another’s privacy, hateful, racially
              objectionable, disparaging, relating to encouraging money
              laundering or gambling or otherwise unlawful in any manner; or
              engages in any activity that interferes with or disrupts access to
              the Platform or the servers/networks; or Falsifies or deletes any
              author attributions, legal or other notices, proprietary
              designations, label soft he origin or source of software,or other
              material on the Platform; or deceives or misleads the addressee
              about the origin of such messages or communicates any information
              which is grossly offensive or menacing or which is patently false
              or misleading but may reasonably be perceived as a fact;
            </p>
            <br></br>
            <p>
              {" "}
              6.4 You agree that in case of non-compliance with applicable laws,
              or with these Terms or with the Privacy Policy, We have the right
              to terminate your access or usage rights to the Platform
              immediately.. You hereby agree that you will never use the
              Platform in violation of any applicable law
            </p>
            <br></br>
            <p>
              {" "}
              6.5 We shall not be liable for any misuse of the Personal
              Information shared by You with Us or a third party on Your profile
              on the Platform.
            </p>
            <br></br>
            <p>
              {" "}
              6.6 We reserve the right to change the Terms from time to time,
              and such changes will apply to You if You continue to use the
              Platform at any time after such changes are posted on the
              Platform. Where We believe that any changes are material, We will
              notify You through either an in-app notification, SMS, push
              notification, or email You have provided Us, but regardless of
              whether You receive such notice, any changes will become effective
              if You use any of the Platform at any time after such changes are
              published on this page. We recommend that you visit this page
              periodically to be sure You are aware of the most recent Terms.If
              You do not wish to agree to any changes to these Terms, please
              cease using the Platform.
            </p>
            <br></br>
            <h3> 7. Payments </h3>
            <br></br>
            <p>
              7.1 You may be required to provide personal information to PMCL
              and may be required to make payment(s) for certain Services
              through credit card, digital wallet, or other payment method
              accepted by PMCL ("Payment Method") that you have selected.{" "}
            </p>
            <br></br>
            <p>
              7.2 You agree, understand and acknowledge that PMCL facilitates a
              third-party payment gateway for processing of payments. This
              facility is managed by a third-party payment gateway provider. For
              certain Payment Methods, you are required to follow all the terms
              and conditions of such third-party payment gateway provider which
              are referred to on the Platform and/or such third-party platform,
              if specified therein. You acknowledge, that you are additionally
              bound by the terms and conditions of the third-party payment
              gateway and/or such third-party platform as per the Payment Method
              used for the payment of the Subscription Fees (as defined below in
              Clause 7.6).{" "}
            </p>
            <br></br>
            <p>
              7.3 You are responsible for the accuracy and authenticity of the
              payment information you provide including the bank account
              number/credit card details and any other information requested
              during the subscription process. You represent and warrant that
              you have the right to use any credit card or other payment
              information (including relating to any Payment Method) that you
              submit. You agree and acknowledge that PMCL shall not be liable
              and in no way be held responsible for any losses whatsoever,
              whether direct, indirect, incidental, or consequential, including
              without limitation any losses due to delay in processing of
              payment instruction or any credit card fraud.{" "}
            </p>
            <br></br>
            <p>
              7.4 Upon payment being received by PMCL through any of the Payment
              Method, PMCL shall make the relevant Service available to you
              through any of the approved modes of delivery that PMCL adopts.
            </p>
            <br></br>
            <p>
              7.5 The fees specified for each Service are inclusive of all
              applicable taxes. We will not be able to notify you of any changes
              in applicable taxes. You are responsible for all third-party
              internet access charges, bank charges and taxes not included
              within the fee in connection with your use of the Platform, which
              may differ based on your service provider. Please check with your
              internet provider, bank, Payment Method service provider for
              information for such charges.
            </p>
            <br></br>
            <p>
              To cancel the service, unsubscribe in the policies tab in the App{" "}
            </p>
            <br></br>
            <p>
              7.7 PMCL reserves the right to change the accepted method of
              payment or billing provider at its sole discretion; PMCL further
              reserves the right to change the subscription fee or run special
              offers from time to time in which it can offer discounted or free
              subscriptions to Billing Provider’s customers.
            </p>
            <br></br>
            <h3> 8. ADVERTISEMENTS, CONTESTS AND PROMOTIONS </h3>
            <br></br>
            <p>
              {" "}
              8.1 PMCL may include or offer third-party products on the Platform
              through digital advertisements, whether fetched directly from
              advertisers or sourced from ad networks. PMCL does not represent
              and warrant that advertisements of the products or Services on the
              Platform or the third party content made available / hosted on the
              third-party sites are true and correct. Accordingly, PMCL takes no
              responsibility over and expressly disclaims any liability arising
              out of, the advertisements or usage of such advertised products or
              services. Any dealings you have with advertisers due to your use
              of the products or services advertised on the Platform are between
              you and the advertiser, and you agree that PMCL is not liable for
              any loss or claim that you may have against an advertiser.{" "}
            </p>
            <br></br>
            <p>
              8.2 PMCL may notify additional terms and conditions (“Additional
              Terms and Conditions”), from time to time, in connection with
              your: (a) participation in any contest, game, or other promotion
              on the Platform conducted by PMCL and/or PMCL’s affiliates and/or
              any third party partner authorized by PMCL, and/or, (b)
              participation in any contest, or promotion, in connection with the
              Platform integrated with a third-party website, service,
              application, platform. If such Additional Terms and Conditions
              conflict with these Terms, the provisions contained in the
              Additional Terms and Conditions will govern and control, to the
              extent of the inconsistency or conflict (as applicable).
            </p>
            <br></br>
            <h3> 9. No Guarantee\ Warranty </h3>
            <br></br>
            <p>
              {" "}
              9.1 The Platform and all information, materials, services and
              functions contained therein including, software programs, data,
              databases, texts, graphics, photographs, animations, audio, music,
              video, links or other materials are provided “as is” and “as
              available”. No warranty of any kind, implied, express or statutory
              including but not limited to any warranties of title,
              non-infringement of third party, merchantability, satisfactory
              quality, compatibility, error-free, reliable, fitness for a
              particular purpose and freedom from computer virus or other
              malicious, destructive or corrupting code, agent, program or
              macros, is given in conjunction with the Platform or service.{" "}
            </p>
            <br></br>
            <p>
              {" "}
              9.2 PMCL does not guarantee that:<br></br>
              (a) The Platform will meet the User’s expectations; or<br></br>
              (b) The Platform will be accessible without interruption or in a
              timely, reliable, or fault-free manner; or <br></br>
              (c) The results obtained through the use of the Platform will be
              as per users' expectations deemed as correct and reliable; or
              <br></br>
              (d) The quality of the products, services, information, or other
              material Won or obtained by the User through the Platform will
              meet the User’s expectations<br></br>
            </p>
            <br></br>
            <p>
              {" "}
              9.3 The User shall be solely responsible for damages to his or her
              data system or for loss of data arising from downloads of content
              from the Platform.
            </p>
            <br></br>
            <p>
              9.4 No guidance or information, written or oral, obtained from XYZ
              or via the Platform, shall constitute any warranty, which has not
              specifically been stated in these Terms.
            </p>
            <br></br>
            <p>
              9.5 In addition, PMCL makes no warranty regarding any
              applications, products or services that may be or which are
              purchased or obtained, or any transactions entered into, through
              the Platform.{" "}
            </p>
            <br></br>
            <h3> 10. Indemnity and Limitation of Liability </h3>
            <br></br>
            <p>
              {" "}
              10.1 The Users shall indemnify, defend, and hold harmless PMCL and
              its affiliates, if any, from and against all losses, liabilities,
              claims, suits, proceedings, penalties, interests, damages,
              demands, costs, and expenses (including legal and statutory fees
              and disbursements in connection therewith and interest chargeable
              thereon) asserted against or incurred by PMCL that arise out of,
              result from, or in connection with:<br></br>
              (a) the User’s breach of these Terms;<br></br>
              (b) any claims made by any third party due to, or arising out of,
              or in connection with User’s use of the Platform or participation
              in a Tournament on the Platform or winning/claiming the reward of
              such Tournament;<br></br>
              (c) the User’s violation of any rights of another, including
              intellectual property rights; and <br></br>
              (d) the User’s violation of any applicable laws.<br></br>
              Notwithstanding anything to the contrary in these Terms, in no
              event shall PMCL and its affiliates, if any, be liable to the User
              or anyone claiming through the User in respect of any subject
              matter of these Terms.<br></br>
            </p>
            <br></br>
            <p>
              {" "}
              10.2 Users specifically acknowledge and agree that in no event
              shall PMCL be liable for any direct or indirect damage, including
              but not limited to, delay, damages caused by unused opportunity of
              the Platform, loss of profits, goodwill, licenses of data, or
              other financial loss arising out of: the use of the Platform or
              inability to use the Platform; costs arisen from acquiring are
              placement of any product or service that is based on any item,
              data, information, or service purchased through the Platform, from
              any message received through the Platform or any transactions made
              using the Platform; third party claims against the subscriber;
              unlawful access to or modifications of data transmissions; or any
              other circumstances related to the Platform.{" "}
            </p>
            <br></br>
            <p>
              10.3 PMCL exempts itself from any claims for damages relating to
              the Platform access, function, quality, and possible errors such
              as spelling errors, bugs, and other factors relating to the
              Platform.
            </p>
            <br></br>
            <p>
              {" "}
              10.4 PMCL shall not be liable for any indirect or unforeseeable
              damages caused to the Users under any given circumstances.{" "}
            </p>
            <br></br>
            <p>
              {" "}
              10.4 PMCL shall not be liable for any indirect or unforeseeable
              damages caused to the Users under any given circumstances.{" "}
            </p>
            <br></br>
            <h3> 11. Force Majeure </h3>
            <br></br>
            <p>
              {" "}
              11.1 PMCL shall not be liable for any damages whatsoever arising
              out of force majeure or other similar circumstances, directly or
              indirectly affecting PMCL and unreasonably complicating PMCL’s
              activities. Examples of force majeure events are real or potential
              labor disputes, governmental actions, war or threat of war,
              sabotage, civil unrest, demonstrations, fire, storm, flooding,
              explosion, earthquake, provisions or limitations of materials or
              resources, inability to obtain the relevant authorization,
              accident, and defect in electricity or telecommunication network.
              Force majeure or other event beyond PMCL’s control hindering,
              delaying or complicating the maintenance of the Platform entitles
              PMCL to suspend or limit the Platform until further notice.
            </p>
            <br></br>
            <h3> 12. TERMINATION OF SERVICE</h3>
            <br></br>
            <p>
              {" "}
              12.1 PMCL reserves the right to immediately terminate, suspend,
              limit, or restrict your use of the PMCL Products and/or access to
              the Platform and/or the Services at any time, without notice or
              liability to you or any other person, if you breach these Terms,
              violate any law, rule, or regulation, or engage in any
              inappropriate conduct in relation to the Platform including the
              content and/or the Services.{" "}
            </p>
            <br></br>
            <p>
              {" "}
              12.2 Without limiting the above, PMCL may restrict or suspend your
              access to your Platform account for cause without notice to you,
              which cause includes but is not limited to (a) requests from law
              enforcement or other government authorities, (b) unexpected
              technical issues or problems, or (c) if PMCL reasonably believes
              that your Platform account has been created fraudulently, or
              anyone uses your Platform account to commit fraud or for any
              purpose other than its intended purpose and in accordance with all
              of the requirements applicable thereto.{" "}
            </p>
            <br></br>
            <p>
              {" "}
              12.3 To the fullest extent permitted by law, you agree that PMCL
              and/or its affiliates will not be liable to you or to any third
              party for any such restriction, suspension or termination of your
              access to your Platform account and/or Services.{" "}
            </p>
            <br></br>
            <p>
              {" "}
              12.4 Termination of these Terms for any reason shall not affect
              the user obligation to make full payment of any fees payable if
              such fee has not already been paid.{" "}
            </p>
            <br></br>
            <p>
              Upon termination of these Terms for any reason whatsoever, all
              rights and/or licenses granted to the user under these Terms shall
              immediately cease and terminate and the user shall forthwith cease
              the access and use of the Platform and the Services in any way
              whatsoever.{" "}
            </p>
            <br></br>
            <h3>13. INFORMATION OR COMPLAINTS</h3>
            <br></br>
            <p>
              {" "}
              If you have any questions about these Terms, the Privacy Notice,
              or your dealings with PMCL, or a question or a complaint about the
              Platform and/or the Services, you can contact customer support
              services by also providing us with your phone number used for
              accessing your Platform, as below:{" "}
            </p>
            <br></br>
            {/* <p><b>WhatsApp Support: </b></p><br></br> */}
            <p>
              <b>Email: </b> info@fikrfree.com.pk
            </p>
            <br></br>
            <br></br>
            <p>
              <b>Helpline: </b> 111
            </p>
            <br></br>
            <br></br>
            <p>
              <b>Address:</b> Jazz DHQ-1, Headquarters, 1-A Kohistan Rd, F-8
              Markaz, Islamabad, 44000
            </p>
            <br></br>
            <br></br>
            <h3>14. GOVERNING LAW AND DISPUTE RESOLUTION</h3>
            <br></br>
            <p>
              {" "}
              14.1 These Terms, including any dispute, controversy, proceeding,
              or claim of whatever nature which may arise out of or in
              connection with these Terms, are governed by the laws of Pakistan
              without regard to the conflict of laws principle, and are subject
              to the disclaimer of warranties and limitation of liability as set
              out herein.{" "}
            </p>
            <br></br>
            <h3>15. Additional Provisions </h3>
            <br></br>
            <p>
              {" "}
              15.1 If, for any reason, a court of competent jurisdiction finds
              any provision of the Terms, or portion thereof, to be
              unenforceable, that portion shall be enforced to the maximum
              extent permissible to give effect to the intent of the parties as
              reflected by that provision. The remainder of the Terms shall
              continue in full force and effect.{" "}
            </p>
            <br></br>
            <p>
              {" "}
              15.2 No waiver by you or PMCL of any breach or default under these
              Terms will be deemed to be a waiver of any preceding or subsequent
              breach or default.{" "}
            </p>
            <br></br>
            <p>
              {" "}
              15.3 Notices to you (including notices of changes to these Terms)
              may be made via posting to the Platform or by issuing e-mail or
              messages to your designated phone number (example: SMS), push
              notifications, in-app notifications (including in each case via
              links), or by regular post.{" "}
            </p>
            <br></br>
            <p>
              {" "}
              15.4 These Terms and the Privacy Notice constitute the entire
              agreement between you and PMCL governing your use of the Platform,
              superseding any prior agreements between you and PMCL regarding
              such use. Further, your acceptance of these Terms constitutes a
              valid and binding agreement between you and PMCL. Should these
              Terms require to be stamped under any applicable stamp duty laws,
              you should bear such stamp duty payable and comply with relevant
              stamping obligations and PMCL shall not be responsible for such
              stamp duty payments.{" "}
            </p>
            <br></br>
            <h3>16. Withdrawal and Refund Policy</h3> <br></br>
            <p>
              Thank you for choosing our insurance services. Once a customer has
              enrolled and paid for a policy, coverage begins immediately, and
              the customer gains access to our comprehensive insurance benefits
              and claims process. Due to the nature of insurance coverage and
              the immediate benefits provided upon payment, we regret to inform
              you that withdrawals and refunds are not permitted after the
              initial payment has been processed.
            </p>
            <br></br>
            <p>
              <b>Policy Details:</b>
            </p>
            <br></br>
            <p>
              {" "}
              1. No Withdrawals: Once payment for the insurance policy is made,
              withdrawals are not allowed.
            </p>
            <br></br>
            <p>
              2. Coverage Activation: Coverage begins upon receipt of payment,
              enabling immediate access to insurance benefits and the ability to
              initiate claims.
            </p>
            <br></br>
            <p>
              3. Refund Policy: There is no provision for refunds once payment
              is processed, as customers immediately benefit from insurance
              coverage and claim potential.
            </p>
          </div>
        </div>
      </div>
    );
}

export default TaqdeerTermsAndConditions