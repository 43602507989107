import React from "react";
import "../../styles/smallLoaderStyle.css";
const SmallLoader = () => {
  return (
    <div className="spinner-container-smallLoader">
      <div className="loading-spinner-smallLoader"></div>
    </div>
  );
};

export default SmallLoader;
