import React, { useEffect, useState } from "react";
import Landing from "../components/Landing";
import {
  generateOTPService,
  checkOperatorService,
} from "../services/LandingService";
import { useNavigate } from "react-router-dom";

const LandingScreen = () => {
  const navigate = useNavigate();
  const [msisdn, setMsisdn] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    let userId = localStorage.getItem("user_Id");
    // console.log("User Id in Landing Screen", userId);
    if (userId) {
      navigate("/home");
    }
  }, []);

  const handleMsisdnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    let regex = new RegExp("^(3[0-9]*|)$");

    if (regex.test(input)) {
      setMsisdn(input);
    }
  };

  const handleOnPressSendOTPButton = async () => {
    setErrorMsg("");
    if (msisdn.length === 10) {
      handleCheckOperator();
    } else {
      setErrorMsg("Please enter a valid 10-digit phone number");
    }
  };
  const handleCheckOperator = async () => {
    try {
      setIsLoading(true);
      let response = await checkOperatorService({ msisdn });
      console.log("isOther Check: ", response.result.isOther);
      if (response.result.isOther === false) {
        console.log("Msisdn belongs to Jazz: ");
        let _package = response.result.package;
        handleSendOTP(_package);
      } else {
        setIsLoading(false);
        setErrorMsg("Please enter a Jazz number to proceed");
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMsg("Something went wrong.Please try again");
      console.log("Error while sending OTP: ", error);
    }
  };

  const handleSendOTP = async (_package: string) => {
    // setIsLoading(true);
    try {
      let response = await generateOTPService({ msisdn });
      if (response.result) {
        console.log("OTP send successfully: ", response.result);

        navigate("/pin", { state: { msisdn, _package } });
      } else {
        setErrorMsg("Something went wrong.Please try again");
      }
    } catch (error) {
      setErrorMsg("Something went wrong.Please try again");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Landing
      msisdn={msisdn}
      errorMsg={errorMsg}
      isLoading={isLoading}
      handleMsisdnChange={handleMsisdnChange}
      handleOnPressSendOTPButton={handleOnPressSendOTPButton}
    />
  );
};

export default LandingScreen;
