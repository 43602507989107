import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faChevronRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

interface PurchaseOTPPopUpProps {
  onHandleClosePurchaseOTPPopUp: () => void;
  showPaymentOtpPopup: boolean;
  otp: string[];
  inputRefs: any;
  handlePINInputChange: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handlePINInput: (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => void;
  showResendButton: boolean;
  timer: number;
  onHandleProceedToPayment: () => void;
  onHandlePressVerifyButton: () => void;
  isLoading: boolean;
  errorMsg: string;
  sendPurchaseOtp: () => void;
  isOTPSending: boolean;
}

const PurchaseOTPPopUp = (props: PurchaseOTPPopUpProps) => {
  return (
    <Modal
      show={props.showPaymentOtpPopup}
      onHide={props.onHandleClosePurchaseOTPPopUp}
      className="verfication-modal verfic-otp-modal"
      // size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>OTP Verification </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="verification-modal-content">
          <div className="v-otp-input">
            {props.otp.map((value, index) => (
              <input
                key={index}
                ref={(el) => (props.inputRefs.current[index] = el)}
                type="tel"
                name="one"
                maxLength={1}
                value={value}
                onChange={(e) => props.handlePINInputChange(index, e)}
                onKeyDown={(e) => props.handlePINInput(index, e)}
              />
            ))}
          </div>
          {props.showResendButton ? (
            <div className="resend-otp">
              <span
                style={{
                  cursor: "pointer",
                  pointerEvents: props.isOTPSending ? "none" : "auto",
                }}
                onClick={props.sendPurchaseOtp}
              >
                Resend
              </span>
            </div>
          ) : (
            <div className="resend-otp">
              <span>
                00:{props.timer < 10 ? `0${props.timer}` : props.timer}
              </span>
            </div>
          )}
          <div className="d-btn">
            {props.isLoading && !props.isOTPSending ? (
              <button>Verifying</button>
            ) : (
              <button onClick={props.onHandlePressVerifyButton}>Verify</button>
            )}
          </div>
          {props.errorMsg && <div className="error-msg">{props.errorMsg}</div>}
          <div className="term-conditon">
            By Signing in, I agree to
            <Link to="/terms" target="_blank">
              Terms & Conditions
            </Link>
            and
            <Link to="/privacypolicy" target="_blank">
              Privacy Policy
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PurchaseOTPPopUp;
