import { ApiNames } from "../constants/ApiNames";
// import { fetchLoginPost } from "./FetchAPI";
import { postRequest, getRequest } from "./NetworkService";

export const generateTokenService = async (requestData: {
  username: string;
  password: string;
}) => {
  let { data }: { data: string } = await postRequest(
    ApiNames.generateToken,
    requestData
  );
  return data;
};

export const getInsuranceCategoriesService = async () => {
  let { data }: { data: any } = await getRequest(ApiNames.insuranceCategories);
  return data;
};

export const getPartnersListService = async (id: number) => {
  let { data }: { data: any } = await getRequest(ApiNames.partnersList + id);
  return data;
};

export const getPartnersPoliciesService = async (
  catId: number,
  partnerId: number
) => {
  let { data }: { data: any } = await getRequest(
    ApiNames.partnerPolicies + catId + "/" + partnerId
  );
  return data;
};

export const getPolicyDetailsService = async (_data: {}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.policyDetails,
    _data
  );
  return data;
};

export const getRelationsService = async () => {
  let { data }: { data: any } = await getRequest(ApiNames.relations);
  return data;
};

export const sendPurchaseOtpService = async (_data: {}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.sendPurchaseOtp,
    _data
  );
  return data;
};

export const purchasePolicyService = async (_data: {}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.purchasePolicy,
    _data
  );
  return data;
};
