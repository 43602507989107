import { ApiNames } from "../constants/ApiNames";
// import { fetchLoginPost } from "./FetchAPI";
import {
  postRequest,
  getRequest,
  postRequestMulitiPart,
} from "./NetworkService";

export const checkOperatorService = async (requestData: { msisdn: string }) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.checkOperator,
    requestData
  );
  return data;
};

export const generateOTPService = async (requestData: { msisdn: string }) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.sendOTP,
    requestData
  );
  return data;
};

export const checkUserService = async (requestData: { msisdn: string }) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.checkUser,
    requestData
  );
  return data;
};

export const subscriptionService = async (requestData: {
  msisdn: string;
  otp: string;
  device_info: string;
  sub_package: string;
}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.subscribeUser,
    requestData
  );
  return data;
};

export const LoginService = async (requestData: {
  msisdn: string;
  otp: string;
  device_info: string;
  sub_package: string;
}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.loginUser,
    requestData
  );
  return data;
};

export const getProfileService = async (requestData: { user_id: number }) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.userProfile,
    requestData
  );
  return data;
};

export const updateProfileService = async (requestData: {
  user_id: any;
  name: string;
  gender: string;
  cnic: number;
  email: string;
  dob: string;
  residential_address: string;
}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.updateProfile,
    requestData
  );
  return data;
};

export const uploadProfilePictureService = async (formData: FormData) => {
  let { data }: { data: any } = await postRequestMulitiPart(
    ApiNames.uploadProfilePicture,
    formData
  );
  return data;
};
