import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/UserProfilePopup.css";
import "../../styles/UserSuccesPopup.css";
import succesCheckImg from "../../Assets/imgs/check-succes-img.webp";
import { propTypes } from "react-bootstrap/esm/Image";

interface UserSuccesPopupProps {
  showSuccessPopUp: boolean;
  handleCloseSuccessPopUp: () => void;
}

// Rename insurranceProvider to InsuranceProvider
const UserSuccesPopup = (props: UserSuccesPopupProps) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* <button onClick={handleShow}>Succes modal</button> */}
      {/* size="xl" */}
      <Modal
        show={props.showSuccessPopUp}
        onHide={props.handleCloseSuccessPopUp}
        className="succes-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="succes-content">
            <div className="sucess-image">
              <img src={succesCheckImg} alt="" />
            </div>
            <div className="heading">Purchased Successfully</div>
            <div className="content">
              You have successfully purchased the Policy
            </div>
            <div className="d-btn">
              <button onClick={props.handleCloseSuccessPopUp}>Close</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserSuccesPopup;
