import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/UserProfilePopup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { formatCnic } from "../../utils/CommonFunctions";
import moment from "moment";

interface UserProfilePopupProps {
  isUserProfileModalVisible: boolean;
  onHandleCloseUserProfileModal: () => void;
  onHandleSetUpUserProfile: (data: any) => void;
  newUserData: any;
  handleNewUserInputChange: any;
  handleSaveUserData: () => void;
  errorMsg: string;
  isLoading: boolean;
}

const UserProfilePopup = (props: UserProfilePopupProps) => {
  return (
    <>
      <Modal
        show={props.isUserProfileModalVisible}
        onHide={props.onHandleCloseUserProfileModal}
        className="user-profile-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="user-profile-model-content">
            <div className="m-heading">Set up your profile</div>
            <div className="m-text">Enter your personal information</div>

            {/* Input-Form-group */}
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                First Name
              </label>
              <input
                type="text"
                // value={userName}
                name="name"
                value={props.newUserData.firstName}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[A-Za-z\s]*$/;

                  if (regex.test(value)) {
                    props.handleNewUserInputChange("firstName", value);
                  }
                }}
                // onChange={handleChangeUserName}
                className="form-control"
                placeholder="Enter First Name Here"
              />
            </div>
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                // value={userName}
                name="name"
                value={props.newUserData.lastName}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[A-Za-z\s]*$/;

                  if (regex.test(value)) {
                    props.handleNewUserInputChange("lastName", value);
                  }
                }}
                // onChange={handleChangeUserName}
                className="form-control"
                placeholder="Enter Last Name Here"
              />
            </div>
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Gender
              </label>
              <select
                className="form-select"
                name="gender"
                // value={userGender}
                value={props.newUserData.gender}
                onChange={(e) => {
                  props.handleNewUserInputChange("gender", e.target.value);
                }}
                // onChange={handleChangeUserGender}
              >
                <option value="" disabled selected>
                  Select Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                CNIC
              </label>
              <input
                type="text"
                // value={userCNIC}
                maxLength={15}
                name="cnic"
                value={props.newUserData.cnic}
                // onChange={props.handleNewUserInputChange}
                onChange={(e) => {
                  const value = e.target.value;
                  let formatted = formatCnic(value);
                  props.handleNewUserInputChange("cnic", formatted);
                }}
                // onChange={handleChangeUserCNIC}
                className="form-control"
                placeholder="xxxxx-xxxxxxxx-x"
              />
            </div>
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email
              </label>
              <input
                // value={userEmail}
                value={props.newUserData.email}
                onChange={(e) => {
                  const value = e.target.value;
                  props.handleNewUserInputChange("email", value);
                }}
                name="email"
                // onChange={handleChangeUserEmail}
                type="email"
                className="form-control"
                placeholder="Enter you Email Address over here"
              />
            </div>
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Residential Address
              </label>
              <input
                // value={userAddress}
                value={props.newUserData.address}
                onChange={(e) => {
                  const value = e.target.value;
                  props.handleNewUserInputChange("address", value);
                }}
                name="address"
                // onChange={handleChangeUserAddress}
                type="text"
                className="form-control"
                placeholder="Enter Residential Address here"
              />
            </div>
            <div className="input-form">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Date of Birth
              </label>
              <input
                type="date"
                className="form-control"
                name="dob"
                onChange={(e) => {
                  props.handleNewUserInputChange("dob", e.target.value);
                }}
                value={props.newUserData.dob}
                max={moment().subtract(18, "years").format("YYYY-MM-DD")}
              />
            </div>
            {props.errorMsg && (
              <div className="error-msg">{props.errorMsg}</div>
            )}
            {props.isLoading ? (
              <div className="d-btn">
                <button>Updating Profile</button>
              </div>
            ) : (
              <div className="d-btn">
                <button onClick={props.handleSaveUserData}>Continue</button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserProfilePopup;
