export const ApiNames = {
  generateToken: "generateToken",
  insuranceCategories: "categories",
  checkOperator: "checkOperator",
  sendOTP: "sendOtp",
  checkUser: "user/checkUser",
  subscribeUser: "subscribe",
  loginUser: "login",
  partnersList: "partners/cat/",
  partnerPolicies: "policy/catPolicies/",
  policyDetails: "policy/details",
  userProfile: "user/profile",
  updateProfile: "user/updateProfile",
  relations: "relations",
  sendPurchaseOtp: "policy/sendPurchaseOtp",
  purchasePolicy: "policy/purchase",
  uploadProfilePicture: "uploadProfilePic",
};
