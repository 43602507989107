import axios, { AxiosResponse } from "axios";
import { baseUrl } from "../utils/Config";
import { ApiNames } from "../constants/ApiNames";

export const getRequest = async (endPoint: string) => {
  let _baseUrl = baseUrl;
  // if (endPoint === ApiNames.he) {
  //   _baseUrl = _baseUrl.replace('https', 'http');
  // }
  const response = await axios({
    url: `${_baseUrl}${endPoint}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Connection: "close",
      Authorization: "Bearer " + global.authToken,
    },
  });

  return response;
};
export const postRequest = async (endPoint: string, data: {}) => {
  const response = await axios({
    url: `${baseUrl}${endPoint}`,
    method: "POST",

    headers: {
      "Content-Type": "application/json",
      Connection: "close",
      Authorization: "Bearer " + global.authToken,
    },
    data: data,
    // timeout: requestTimeout,
  });

  return response;
};

export const postRequestMulitiPart = async (endPoint: string, data: {}) => {
  const response = await axios({
    url: `${baseUrl}${endPoint}`,
    method: "POST",

    headers: {
      "Content-Type": "multipart/form-data",
      Connection: "close",
      Authorization: "Bearer " + global.authToken,
    },
    data: data,
    // timeout: requestTimeout,
  });

  return response;
};
