import React, { useEffect, useRef, useState } from "react";
import Profile from "../components/Profile";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCnic } from "../utils/CommonFunctions";
import moment from "moment";
import {
  updateProfileService,
  uploadProfilePictureService,
} from "../services/LandingService";
import AppMessage from "../constants/AppMessage";

let userId = 0;
let previousData: any = {};
let previousGender: string = "";
let previousDateOfBirth: any = "";

const ProfileScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isImageUpdating, setIsImageUpdating] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState<any>("");
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [inputValue, setInputValue] = useState({
    firstName: "",
    lastName: "",
    cnic: "",
    email: "",
    residentialAddress: "",
  });
  const [inputError, setInputError] = useState({
    firstName: false,
    lastName: false,
    cnic: false,
    email: false,
    residentialAddress: false,
    dob: false,
  });
  const [errorText, setErrorText] = useState({
    firstName: "",
    lastName: "",
    email: "",
    cnic: "",
    gender: "",
    residentialAddress: "",
    dob: "",
  });

  const imgInputRef = useRef<HTMLInputElement | null>(null);

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    let _userId = localStorage.getItem("user_Id");
    userId = _userId ? parseInt(_userId) : 0;
    // console.log("User ID in profile Screen", userId);
    if (!userId || !location.state) {
      window.location.href = "/home";
    } else if (location.state) {
      let personalDataState = location.state.personalData as any;

      prefillData(personalDataState);
    }
  }, []);

  const prefillData = (data: any) => {
    try {
      let userData = data;
      setInputValue({
        firstName: userData.name ? userData.name.split(" ")[0] : "",
        lastName: userData.name
          ? userData.name.split(" ")[1]
            ? userData.name.split(" ")[1]
            : ""
          : "",
        cnic: userData.cnic ? formatCnic(userData.cnic.toString()) : "",
        email: userData.email ? userData.email : "",
        residentialAddress: userData.residential_address
          ? userData.residential_address
          : "",
      });
      setGender(userData.gender ? userData.gender : "");
      // console.log(moment(userData.dob).toDate());
      setDateOfBirth(
        userData.dob ? moment(userData.dob).format("YYYY-MM-DD") : ""
      );

      setSelectedImage(
        userData.profile_pic_url
          ? userData.profile_pic_url + "?time=" + new Date()
          : ""
      );
      previousData = {
        firstName: userData.name ? userData.name.split(" ")[0] : "",
        lastName: userData.name
          ? userData.name.split(" ")[1]
            ? userData.name.split(" ")[1]
            : ""
          : "",
        cnic: userData.cnic ? formatCnic(userData.cnic.toString()) : "",
        email: userData.email ? userData.email : "",
        residentialAddress: userData.residential_address
          ? userData.residential_address
          : "",
      };
      previousGender = userData.gender ? userData.gender : "";
      previousDateOfBirth = userData.dob
        ? moment(userData.dob).format("YYYY-MM-DD")
        : "";
      // console.log("Previous Data::::", previousData);
      // console.log("Date of Personal in profile Screen", userData.dob);
    } catch (e) {}
  };

  const onInputValueChange = (fieldName: string, val: string) => {
    setInputError({ ...inputError, [fieldName]: "" });
    setInputValue({ ...inputValue, [fieldName]: val });
  };

  const onOpenImageDialog = () => {
    imgInputRef.current?.click();
  };

  const onImageSelect = (e: any) => {
    try {
      console.log("File Selected:::::", e);

      const maxFileSize = 3 * 1024 * 1024;
      const file: File[] = Array.from(e.target.files);
      // const validFiles: File[] = [];
      console.log("File is", file);

      if (!file) {
        setErrorMsg("No file selected.");
        return;
      }

      if (file[0].size > maxFileSize) {
        console.log("File is not acceptable");
        setErrorMsg("File size exceeds the 50MB limit.");
        return;
      }

      const validTypes = ["image/png", "image/webp", "image/jpg", "image/jpeg"];
      if (!validTypes.includes(file[0].type.toString())) {
        // console.log("File types:: ", file[0].type.toString(), validTypes);
        setErrorMsg(
          "Invalid file type. Only PNG, WEBP, JPG, and JPEG are allowed."
        );
        return;
      }

      onSubmitProfilePicture(file[0]);
      e.target.value = "";
    } catch (error) {
      setErrorMsg("An error occurred while selecting the image.");
    }
  };

  const onSubmitProfilePicture = async (file: any) => {
    console.log("Image data for uploading::::", file);
    try {
      setIsImageUpdating(true);
      let form = new FormData();
      form.append("user_id", userId.toString());
      form.append(`profile_pic`, file);
      console.log("Outgoing Form::::", form, userId, file);
      let response = await uploadProfilePictureService(form);
      setIsImageUpdating(false);
      if (response && response.result) {
        let imageExt = file.name.split(".").pop();
        console.log("Uploading Image Response::::", response, imageExt);
        let uploadedImageUrl =
          `/uploads/profilePicFolder/${userId}.${imageExt}` +
          "?time=" +
          new Date();
        setSelectedImage(uploadedImageUrl);
        setErrorMsg(AppMessage.imageUploadSuccess);
      } else {
        setErrorMsg(AppMessage.requestFailed);
      }
    } catch (error) {
      setErrorMsg(AppMessage.requestFailed);
    } finally {
      setIsImageUpdating(false);
    }
  };

  const onSubmitData = () => {
    setErrorMsg("");
    let _inputValue = inputValue;
    let error = false;
    let _inputError = { ...inputError };
    let _errorText = {
      firstName: "",
      lastName: "",
      email: "",
      cnic: "",
      gender: "",
      residentialAddress: "",
      dob: "",
    };
    let isDataChanged = false;

    // Check if any data has changed
    Object.keys(_inputValue).forEach((key) => {
      if (
        _inputValue[key as keyof typeof _inputValue].trim() !==
        previousData[key]
      ) {
        isDataChanged = true;
      }
    });

    console.log("Is Data Changed::::", isDataChanged);

    if (!isDataChanged) {
      setErrorMsg("Nothing to Update");
      return;
    }

    Object.keys(_inputValue).map((key) => {
      if (_inputValue[key as keyof typeof _inputValue].trim().length === 0) {
        _inputError[key as keyof typeof _inputValue] = true;
        _errorText[key as keyof typeof _inputValue] =
          AppMessage.IncompleteField;
        error = true;
      } else {
        _inputError[key as keyof typeof _inputValue] = false;
        _errorText[key as keyof typeof _inputValue] = "";
      }
    });

    if (
      _inputValue.cnic.trim().length !== 15 ||
      !/^[1-7]/.test(_inputValue.cnic[0])
    ) {
      _inputError.cnic = true;
      _errorText.cnic = AppMessage.cnicLength;
      error = true;
    }
    let emailRegex = new RegExp(
      "^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$"
    );
    if (_inputValue.email.trim().length === 0) {
      _inputError.email = true;
      _errorText.email = AppMessage.IncompleteField;
      error = true;
    } else if (!emailRegex.test(_inputValue.email)) {
      _inputError.email = true;
      _errorText.email = AppMessage.invalidEmail;
      error = true;
    }

    if (gender.length === 0) {
      _errorText.gender = "Please Select your Gender";
      console.log(gender.length);
      error = true;
    }
    if (!dateOfBirth) {
      _inputError.dob = true;
      _errorText.dob = "Please select date of Birth";
      error = true;
      console.log("Date of Birth is empty");
    }
    if (dateOfBirth && moment().diff(dateOfBirth, "years") < 18) {
      _inputError.dob = true;
      _errorText.dob = "You must be 18 years old to register";
      error = true;
    }
    setInputError(_inputError);
    setErrorText(_errorText);

    if (!error) {
      updateUserProfile();
      // if (userContext.userData.id) updateUserProfile(userContext.userData.id);
    } else {
      console.warn("Validation errors:", _inputError);
    }
  };

  const updateUserProfile = async () => {
    console.log("Data to be updated::::", inputValue);
    try {
      setIsLoading(true);
      let _data = {
        user_id: userId,
        name: inputValue.firstName.trim() + " " + inputValue.lastName.trim(),
        gender: gender,
        cnic: parseInt(inputValue.cnic.replaceAll("-", ""), 10),
        email: inputValue.email,
        dob: moment(dateOfBirth).format("YYYY-MM-DD"),
        residential_address: inputValue.residentialAddress,
      };
      console.log("Outgoing Data::::", _data);
      let response = await updateProfileService(_data);
      if (response.result) {
        previousData = {
          firstName: inputValue.firstName,
          lastName: inputValue.lastName,
          cnic: inputValue.cnic,
          email: inputValue.email,
          residential_address: inputValue.residentialAddress,
        };
        previousDateOfBirth = dateOfBirth;
        previousGender = gender;
        // dob: dateOfBirth,

        console.log("User Details Updated Successfully");
        setErrorMsg(AppMessage.profileUpdateSuccess);
        window.location.replace("/home");
      } else {
        setErrorMsg("Profile Update Failed");
      }
    } catch (error) {
      console.log("Error while updating user details: ", error);
      setErrorMsg("Unable to update profile please try again");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Profile
      inputValue={inputValue}
      gender={gender}
      dateOfBirth={dateOfBirth}
      selectedImage={selectedImage}
      onAddressChange={(val: string) => {
        if (!val.startsWith(" ")) {
          onInputValueChange("residentialAddress", val);
        }
      }}
      onNameChange={(val: string) => {
        let regex = new RegExp("^[a-zA-Z ]*$");
        if (regex.test(val) && !val.startsWith(" ")) {
          onInputValueChange("firstName", val);
        }
      }}
      onLastNameChange={(val: string) => {
        let regex = new RegExp("^[a-zA-Z ]*$");
        if (regex.test(val) && !val.startsWith(" ")) {
          onInputValueChange("lastName", val);
        }
      }}
      onCnicChange={(val: string) => {
        onInputValueChange("cnic", val);
      }}
      onEmailChange={(val: string) => {
        if (!val.startsWith(" ")) {
          onInputValueChange("email", val);
        }
      }}
      onGenderChange={(val: any) => {
        setGender(val);
      }}
      onDateOfBirthChange={(val: any) => {
        setDateOfBirth(val);
      }}
      onSubmitData={onSubmitData}
      imgInputRef={imgInputRef}
      onOpenImageDialog={onOpenImageDialog}
      onImageSelect={onImageSelect}
      errorMsg={errorMsg}
      inputError={inputError}
      errorText={errorText}
      isLoading={isLoading}
      isImageUpdating={isImageUpdating}
    />
  );
};

export default ProfileScreen;
