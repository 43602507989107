import React from "react";
import { Link } from "react-router-dom";
import LogoImage from "../Assets/imgs/logo.webp";
import landingContentImage from "../Assets/imgs/landing/main-content.webp";
import "../styles/Landing.css";

interface LandingProps {
  msisdn: string;
  handleMsisdnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnPressSendOTPButton: () => void;
  errorMsg: string;
  isLoading: boolean;
  // showError: boolean;
}

const Landing = (props: LandingProps) => {
  return (
    <>
      <div className="landing-section">
        <div className="landing-content">
          <div className="logo-image">
            <img src={LogoImage} alt="Logo-image" width={80} height={82} />
          </div>
          <div className="landing-home-image">
            <img
              src={landingContentImage}
              alt="landing-content-image"
              width={375}
              height={383}
            />
          </div>
          <div className="fusion-form">
            <div className="text">Enter Phone Number</div>
            <div className="fusion-input">
              <span>+92</span>
              <input
                type="tel"
                value={props.msisdn}
                placeholder="Enter your Phone Number"
                onChange={props.handleMsisdnChange}
                maxLength={10}
              />
            </div>
            {props.errorMsg.length > 0 ? (
              <p className="error">{props.errorMsg}</p>
            ) : null}
            {props.isLoading ? (
              <button>Sending OTP</button>
            ) : (
              <button onClick={props.handleOnPressSendOTPButton}>LOGIN</button>
            )}
            <div className="term-conditon">
              <Link to="/privacypolicy" target="_blank">
                Privacy Policy
              </Link>
              <div className="dot"></div>
              <Link to="/terms" target="_blank">
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
