import React, { useState, useEffect } from "react";
import "../styles/Landing.css";
import { Link } from "react-router-dom";
import LogoImage from "../Assets/imgs/logo.webp";
import landingContentImage from "../Assets/imgs/landing/main-content.webp";

interface OTPEntryProps {
  otp: string[];
  inputRefs: any;
  errorMsg: string;
  handlePINInputChange: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handlePINInput: (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => void;
  handleResendOTP: () => void;
  showResendButton: boolean;
  timer: number;
  handleOTPSubmit: () => void;
  isLoading: boolean;
  isOTPSending: boolean;
}

const Pin = (props: OTPEntryProps) => {
  return (
    <>
      <div className="landing-section">
        <div className="landing-content">
          <div className="logo-image">
            <img src={LogoImage} alt="Logo-image" width={80} height={82} />
          </div>
          <div className="landing-home-image">
            <img
              src={landingContentImage}
              alt="landing-content-image"
              width={375}
              height={383}
            />
          </div>
          <div className="fusion-form otp-form">
            <div className="text">Enter OTP</div>
            <div className="fusion-input">
              {props.otp.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => (props.inputRefs.current[index] = el)}
                  type="tel"
                  name="one"
                  maxLength={1}
                  value={value}
                  onChange={(e) => props.handlePINInputChange(index, e)}
                  onKeyDown={(e) => props.handlePINInput(index, e)}
                />
              ))}
            </div>
            {props.errorMsg.length > 0 ? (
              <p className="error">{props.errorMsg}</p>
            ) : null}

            <div className="charges-text">
              <div className="resend-code">
                <div className="timer-text">
                  {props.timer > 0 ? (
                    <div className="not-received">
                      00:{props.timer < 10 ? `0${props.timer}` : props.timer}
                    </div>
                  ) : (
                    <>
                      Didn't Receive an OTP?
                      {props.isOTPSending ? (
                        <span>Resending OTP</span>
                      ) : (
                        <span onClick={props.handleResendOTP}>Resend</span>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {props.isLoading ? (
              <button>Verifying</button>
            ) : (
              <button onClick={props.handleOTPSubmit}>LOGIN</button>
            )}

            {/* <div className="charges-text">Prepaid charges : Rs 10/Day*</div> */}
            <div className="term-conditon">
              <Link to="/privacypolicy" target="_blank">
                Privacy Policy
              </Link>
              <div className="dot"></div>
              <Link to="/terms" target="_blank">
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pin;
